import React, { useState } from "react"
import {
  Box,
  FormHelperText,
  Stack,
  Wrap,
  Input,
  useRadio,
  useRadioGroup,
  VStack,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Text,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react"
import { useFieldArray } from "react-hook-form"

const Menu = (props, ref) => {
  const title = props.title
  const control = props.control
  const first = props.first
  const [comAccordion, setAccordion] = useState(false)
  const { fields, append, remove } = useFieldArray({
    control,
    name: "menu",
  })
  console.log(fields + "At current rerender")
  return (
    <Accordion allowToggle onChange={() => setAccordion}  >
      <AccordionItem>
        <AccordionButton bg={"#F6DD8F"} >{first ? "CREATE COMMISSION FORM":"UPDATE COMMISSION FORM"}</AccordionButton>
        <AccordionPanel>
          <Box>
            <Stack key={title || "MenuTitle"}>
              <FormControl isRequired={comAccordion}>
                <FormLabel>Commission Menu</FormLabel>
                <FormHelperText>
                  Create a Commission Menu with a unique title, the art pieces
                  you do and your prices.
                </FormHelperText>
                <InputGroup>
                  <Input
                    defaultValue={title || "Menu Title"}
                    ref={props.register}
                    name={"menuHeading"}
                  ></Input>
                  <InputRightAddon
                    p={0}
                    bg={"#F6DD8F"}
                    children={
                      <Button
                        aria-label={"Add a menu field"}
                        onClick={() => append()}
                        size={"sm"}
                        bg={"#F6DD8F"}
                        fontWeight={"bold"}
                      >
                        Add Row
                      </Button>
                    }
                  />
                </InputGroup>
                {console.log(fields)}
                {fields.map((field, index) => (
                  <Wrap key={field.id} m={1} mt={2}>
                    <InputGroup size={"sm"}>
                      <Input
                        placeholder={"Add a category"}
                        defaultValue={field.service}
                        onSubmit={() => {
                          console.log("Edited field" + index)
                        }}
                        ref={props.register}
                        name={"menu." + index + ".service"}
                      />
                      <Input
                        defaultValue={field.price}
                        placeholder={"1.00"}
                        onSubmit={() => console.log("Edited item price")}
                        size={"sm"}
                        type={"number"}
                        maxW={"3.2rem"}
                        name={"menu." + index + ".price"}
                        ref={props.register}
                      />
                      <InputRightAddon
                        p={0}
                        bg={"#F6DD8F"}
                        children={
                          <Button
                            bg={"#F6DD8F"}
                            fontWeight={"bold"}
                            onClick={() => remove(index)}
                            size={"sm"}
                          >
                            Remove
                          </Button>
                        }
                      />
                    </InputGroup>
                  </Wrap>
                ))}
              </FormControl>
            </Stack>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
const RadioCard = (props, ref) => {
  const { getInputProps, getCheckboxProps } = useRadio()

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" isFullWidth>
      <input
        //Needed this tag to set the ref and name of the radio card component.
        {...input}
        ref={props.register}
        name={"menu"}
        value={props.data}
      />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "green.600",
          color: "white",
          borderColor: "green.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        align={"space-between"}
      >
        {props.children}
      </Box>
    </Box>
  )
}
export const ReadMenu = props => {
  const fields = props.field
  fields.map(data => console.log(data.service))
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "menu",
    onChange: console.log,
  })

  const group = getRootProps()
  return (
    <Box
      bg={"white"}
      my={5}
      p={5}
      mx={"auto"}
      minW={{ base: "100%", md: "80%" }}
      shadow="md"
      borderWidth="1px"
      borderRadius="md"
      direction={"row"}
    >
      <FormControl isRequired>
        <FormLabel>{props.head}</FormLabel>
        <FormHelperText>Choose what you'd like to commission.</FormHelperText>
        <VStack {...group} mt={5} align={"stretch"}>
          {fields.map(data => {
            const radio = getRadioProps({
              value: data.service,
            })
            return (
              <RadioCard
                key={data.service + data.price}
                {...radio}
                register={props.register}
                data={data.service}
              >
                <Text justify={"space-between"}>
                  {data.service + ""} {data.price + "$"}
                </Text>
              </RadioCard>
            )
          })}
        </VStack>
      </FormControl>
    </Box>
  )
}

export default Menu
