//Helper functions for the web app

//Only allow free signups from artists.
import * as Papa from "papaparse"

export const checkPaymentsValid = async (id: string): Promise<boolean> => {
  let requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      id: id,
    }),
  }
  return await fetch(
    "https://www.lokalcr.com/.netlify/functions/valid",
    requestOptions
  )
    .then(response => response.json())
    .then(res => {
      return res
    })
}
//When null is returned here it means there is no mailing list yet.
export const getMailingList = (
  user: string,
  firebase: any,
  subscription: string = null
): Array<any> => {
  return firebase
    .firestore()
    .collection("artists")
    .doc(user)
    .collection("details")
    .doc("mailingList")
    .get()
    .then((doc: firebase.firestore.DocumentData) => {
      if (doc.exists) {
        if (subscription) {
          let data = [doc.data()]
          let today = Date.now()
          let filtered = data.filter(item => {
            return today < item.current_end
          })
          console.log(filtered)
          return filtered
        }
        let data = [doc.data()]
        console.log(data)
        return data
      } else {
        return null
      }
    })
    .catch(err => {
      return err
    })
}
export const addToMailingList = async (
  email: string,
  user: string,
  subscription: string = "free",
  firebase: any
): Promise<boolean> => {
  let date = Date.now()
  try {
    await firebase
      .firestore()
      .collection("artists")
      .doc(user)
      .collection("details")
      .doc("mailingList")
      .set(
        {
          [email]: {
            sign_up_date: date,
            type: subscription,
            revenue: 0,
          },
        },
        { merge: true }
      )
    return true
  } catch (error) {
    return false
  }
}
export const bulkAddToMailingList = async (
  email: Object,
  user: string,
  firebase: any
): Promise<boolean> => {
  console.log(email)
  return firebase
    .firestore()
    .collection("artists")
    .doc(user)
    .collection("details")
    .doc("mailingList")
    .set(email, { merge: true })
    .catch(err => console.log(err))
}
export const importGmailMailingList = async (): Promise<Array<any>> => {
  let list = []
  await fetch(
    "https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses&key=AIzaSyACXnhbYWWkn-6cqQmwCqlbS-U1GcLOGGQ"
  )
    .then(res => {
      console.log(res)
      return res.json()
    })
    .then(response => {
      console.log("The response is: " + response)
      list.push(JSON.stringify(response.connections))
    })
    .catch(err => console.log(err))
  return list
}
export const importCSVMailingList = async (
  file: File,
  user: string,
  firebase: any
): Promise<Object> => {
  let results = []
  let date = Date.now()
  Papa.parse(file, {
    header: true,
    transformHeader: header => {
      return header.replace(/[-\s]/g, "")
    },
    complete: async res => {
      //console.log("Parsing results data is :" + res.data)
      //This filter removes all falsy values.

      let justEmails = await res.data.map(item => {
        if (item.Email1Value) {
          return item.Email1Value
        }
      })
      let filtered = await justEmails.filter(Boolean)
      let bulkList = []
      let bulkObject = {}
      await filtered.map(item => {
        let userObject = {}
        userObject[item] = { sign_up_date: date, type: "free", revenue: 0 }
        bulkList.push(userObject)
        bulkObject[item] = { sign_up_date: date, type: "free", revenue: 0 }
      })
      console.log("Bulk list looks like first entry: " + bulkList)
      //let bulkObject = { ...bulkList }
      console.log("Object of objects is:" + JSON.stringify(bulkList))
      bulkAddToMailingList(bulkObject, user, firebase)
      return results.push(bulkObject)
    },
  })
  return results
}
